import request from '@/utils/request'
import store from '@/store'
import _g from '../../src/global'

const ProfilePegawaiService = {
  listSKPD,
  listPegawai,
  pegawai,
  nilaiReview,
  kehadiran,
  hukumanDisiplin,
  targetKinerja,
  targetKinerjaDetail,
  assesment,
  hasilScoring,
  grid,
  analisaKesenjangan,
  getListRole,
  getUserRole,
  storeRole,
  getListPLT,
  getUserPLTRole,
  storePLT,
  getAnalisaKesenjanganKinerja,
  resetPassword,
  getStatusPegawai,
  getListInaktif,
  simpanStatusPegawai,
  hapusStatusPegawai
}

function listSKPD(){
  return request({
    url: '/page2/list-skpd',
    method: 'post',
  })
}

function listPegawai({skpd, cari, row_per_page, page}){
  let current = store.state.user.data
  var date = new Date()
  var bulan = date.getMonth()
  return request({
    url:'/page2/list-pegawai',
    method:'post',
    data:{
      "tahun":current.tahun,
      "bulan":bulan,
      "skpd":skpd,
      "cari":cari,
      "row_per_page":row_per_page,
      "page":page
    }
  })
}

function pegawai({nip}){
  let current = store.state.user.data
  var date = new Date()
  var bulan = date.getMonth()
  return request({
    url:'/pegawai-all-page',
    method:'post',
    data:{
      "nip":nip,
      "tahun":current.tahun,
      "bulan":bulan,
      "triwulan":2
    }
  })
}

function nilaiReview({nip}){
  let current = store.state.user.data
  var date = new Date()
  var bulan = date.getMonth()
  return request({
    url:'/page4/nilai-review',
    method:'post',
    data:{
      "nip":nip,
      "bulan":bulan,
      "tahun":current.tahun
    }
  })
}

function kehadiran({nip}){
  let current = store.state.user.data
  var date = new Date()
  var bulan = date.getMonth()
  return request({
    url:'/page5/kehadiran',
    method:'post',
    data:{
      "nip":nip,
      "bulan":bulan,
      "tahun":current.tahun
    }
  })
}

function hukumanDisiplin({nip}){
  let current = store.state.user.data
  var date = new Date()
  var bulan = date.getMonth()
  return request({
    url:'/page5/hukuman-disiplin',
    method:'post',
    data:{
      "nip":nip,
      "bulan":bulan,
      "tahun":current.tahun
    }
  })
}

function targetKinerja({nip}){
  let current = store.state.user.data
  return request({
    url:'/page6/target-kinerja',
    method:'post',
    data:{
      "nip":nip,
      "tahun":current.tahun,
    }
  })
}

function targetKinerjaDetail({nip}){
  let current = store.state.user.data
  return request({
    url:'/page6/target-kinerja-detil',
    method:'post',
    data:{
      "nip":nip,
      "tahun":current.tahun,
    }
  })
}

function assesment({nip}){
  let current = store.state.user.data
  //TODO: hasilnya salah, harusnya digunakan untuk tab nilai review
  var date = new Date()
  var bulan = date.getMonth()
  return request({
    url:'/page7/assessment',
    method:'post',
    data:{
      "nip":nip,
      "bulan":bulan,
      "tahun":current.tahun
    }
  })
}

function hasilScoring({nip}){
  let current = store.state.user.data
  var date = new Date()
  var bulan = date.getMonth()
  return request({
    url:'/page7/hasil-scoring',
    method:'post',
    data:{
      "nip":nip,
      "bulan":bulan,
      "tahun":current.tahun
    }
  })
}

function grid({nip}){
  let current = store.state.user.data
  return request({
    url:'/page8/grid',
    method:'post',
    data:{
      "nip":nip,
      "tahun":current.tahun,
    }
  })
}

function analisaKesenjangan({nip}){
  let current = store.state.user.data
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    nip = _g.overrideWithPLT(user.current.role)
  }else{
    nip = user.current.username
  }
  return request({
    url:'/page8/analisa-kesenjangan',
    method:'post',
    data:{
      "nip":nip,
      "tahun":current.tahun,
    }
  })
}

function getListRole(){
  return request({
    url:'/list-role',
    method:'post'
  })
}

function getUserRole(nip){
  return request({
    url:'/user-role',
    method:'post',
    data: {
      "nip": nip
    }
  })
}

function storeRole(nip, roles){
  return request({
    url:'/user/role-add',
    method:'post',
    data: {
      "username": nip,
      "roles": roles
    }
  })
}

function getListPLT(){
  return request({
    url:'/list-plt',
    method:'post'
  })
}

function getUserPLTRole(nip){
  return request({
    url:'/user-plt',
    method:'post',
    data: {
      "nip": nip
    }
  })
}

function storePLT(nip, plt, bulan){
  return request({
    url:'/user-plt-add',
    method:'post',
    data: {
      "nip": nip,
      "plt": plt,
      "bulan": bulan
    }
  })
}

function getAnalisaKesenjanganKinerja({nip}){
  return request({
    url:'/get-analisa-kesenjangan-kinerja',
    method:'post',
    data:{
      "nip":nip,
    }
  })
}

function resetPassword(nip, pwd, pwd2){
  return request({
    url:'/auth/setpwd',
    method:'post',
    data:{
      "username":nip,
      "newpassword":pwd,
      "newpassword2":pwd2
    }
  })
}

function getStatusPegawai(data){
  return request({
    url: 'admin/status-pegawai/get',
    method:'post',
    data:{
      "nip":data.nip
    }
  })
}

function getListInaktif(){
  return request({
    url: 'admin/status-pegawai/inaktif',
    method:'post'
  })
}

function simpanStatusPegawai(data){
  let user = store.state.user
  let nip = ''
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    nip = _g.overrideWithPLT(user.current.role)
  }else{
    nip = user.current.username
  }
  return request({
    url: 'admin/status-pegawai/simpan',
    method:'post',
    data:{
      "nip":nip,
      "id":data.id,
      "peg_id":data.peg_id,
      "inaktif_mulai":data.inaktif_mulai,
      "inaktif_selesai":data.inaktif_selesai,
      "inaktif_keterangan":data.inaktif_keterangan,
      "inaktif_ket":data.inaktif_ket
    }
  })
}

function hapusStatusPegawai(id){
  return request({
    url: 'admin/status-pegawai/delete',
    method:'post',
    data:{
      "id":id
    }
  })
}

export default ProfilePegawaiService
