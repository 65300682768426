<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <crud
            :crudSettings="crudSettings"
            :headers="headers"
            :responseData="userData"
            :forceUpdateTable="forceUpdate"
            :dataLoading="loading"
            @onTableChange="updateTable"
          >
            <template v-slot:prepend-header>
              <v-autocomplete style="max-width:300px" v-model="skpd" label="SKPD" :items="listSKPD" item-text="nama_skpd" item-value="kode_skpd" clearable hide-details></v-autocomplete>
              <v-divider class="mx-4" inset vertical></v-divider>
            </template>

            <template v-slot:append-actions="{item}">
              <!-- <v-icon title="ganti peran" class="mr-2" @click="dialogRole=true;getUserData(item)">
                mdi-account-details
              </v-icon> -->
              <!-- <v-icon title="reset password" class="mr-2" @click="dialogReset=true;resetPassword(item)">
                mdi-lock-reset
              </v-icon> -->
              <v-btn x-small color="error" class="ma-1" @click="resetPassword(item)" :loading="loadingResetPassword == item.nip">
                <v-icon x-small title="reset password" class="mr-2">
                mdi-lock-reset
                </v-icon>
                Reset Password
              </v-btn>
              <v-btn x-small color="primary" class="ma-1" @click="editStatus(item)" :loading="loadingEditStatus == item.nip">
                <v-icon x-small title="Edit Status" class="mr-2">
                mdi-account
                </v-icon>
                Edit Status
              </v-btn>
              <v-btn x-small color="info" class="ma-1" @click="getDetailUser(item)">
                <v-icon x-small title="detail">
                  mdi-magnify
                </v-icon>
                Detail
              </v-btn>
            </template>
          </crud>
        </base-material-card>
      </v-col>
    </v-row>

    <!-- edit status pegawai -->
    <v-dialog v-model="dialogEditStatus" max-width="900px" >
      <v-card>
        <v-card-title>
          <span class="headline">Status Pegawai</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <div class="d-flex flex-column justify-center align-center">
                <v-avatar class="rounded-circle" tile size="100"> <v-img :src="itemsEditStatus.foto"></v-img></v-avatar>
                <h3 align="center" class="mt-1 orange--text text--darken-2">{{itemsEditStatus.peg_nama}}</h3>
                <div align="center" class="mt-1 grey--text">NIP. {{itemsEditStatus.peg_nip}}</div>
                <font align="center" size="2" class="mt-1 green--text">{{itemsEditStatus.nama_jabatan}}</font>
              </div>
            </v-col>
            <v-col cols="8">
              <v-data-table
                :headers="headersEditStatus"
                :items="itemsEditStatus.list_status"
                :loading="loadingTableEditStatus"
              >
                <template v-slot:item.status="{item}">
                  <b>{{ item.inaktif_keterangan }} {{item.keterangan ? '(' + item.keterangan + ')' : ''}}</b>
                </template>
                <template v-slot:item.dari_tanggal="{ item }">
                  {{ item.inaktif_mulai}}
                </template>
                <template v-slot:item.sampai_tanggal="{ item }">
                  {{ item.inaktif_selesai}}
                </template>
                <template v-slot:item.aksi="{item}">
                  <!-- <v-btn color="primary" small @click="setMereview(item, index)" :loading="gantiReviewerPegawai_ == index"><v-icon>mdi-checklist</v-icon>Pilih</v-btn> -->
                  <b><a @click="showListStatus(item)"><v-icon x-small title="Ubah" class="mr-2">mdi-pencil</v-icon> Ubah</a></b>
                  <br>
                  <b><a @click="hapusStatus(item)"><v-icon x-small title="Hapus" class="mr-2">mdi-delete</v-icon> Hapus</a></b>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row class="justify-end align-end">
            <div>
            <v-btn small class="ma-1" color="green white--text" @click="showListStatus(null)">
              <v-icon small title="detail">
                mdi-plus
              </v-icon>
              Tambah
            </v-btn>
            </div>
          </v-row>
          <v-row>
            <v-expand-transition>
              <div v-if="showMenuStatus">
                <h4>{{ status_editor.id == null ? 'Tambah' : 'Ubah' }} Status</h4>
                <div>
                  <v-row class="pb-9 pt-3">
                    <v-col cols="2">
                      <b><label>Pilih Status</label></b>
                    </v-col>
                    <v-col>
                      <v-row class="pt-3">
                        <v-col cols="4" v-for="status in statusInaktif" v-bind:key="status" class="pt-0 pb-0">
                          <!-- <input type="radio" name="inaktif_keterangan" v-model="status_editor.inaktif_keterangan">&ensp; {{ status }} -->
                          <b>
                          <v-radio-group class="mt-0" v-model="status_editor.inaktif_keterangan" dense>
                            <v-radio :label="status" :value="status"></v-radio>
                          </v-radio-group>
                          </b>
                        </v-col>
                      </v-row><br>
                      <label>
                      <em>*Untuk status pegawai titipan, dipekerjakan, atau diperbantukan, silakan ubah melalui <b>SIMPEG</b></em>
                      </label>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="status_editor.inaktif_keterangan == 'Menerima hukuman disiplin'">
                  <v-row>
                    <v-col class="pt-8" cols="2">
                      <b><label>Pilih Jenis Hukdis</label></b>
                    </v-col>
                    <v-col>
                      <v-row>
                        <v-radio-group v-model="status_editor.keterangan" row>
                        <v-col cols="4">
                          <label>
                            <!-- <input type="radio" name="inaktif_keterangan_hukdis" value="ringan" v-model="status_editor.keterangan"> Ringan -->
                            <v-radio color="blue" value="ringan">
                              <template v-slot:label>
                                <strong class="blue--text">Ringan</strong>
                              </template>
                            </v-radio>
                          </label>
                        </v-col>
                        <v-col cols="4">
                          <label>
                            <!-- <input type="radio" name="inaktif_keterangan_hukdis" value="sedang" v-model="status_editor.keterangan"> Sedang -->
                            <v-radio color="orange" value="sedang">
                              <template v-slot:label>
                                <strong class="orange--text">Sedang</strong>
                              </template>
                            </v-radio>
                          </label>
                        </v-col>
                        <v-col cols="4">
                          <label>
                            <!-- <input type="radio" name="inaktif_keterangan_hukdis" value="berat" v-model="status_editor.keterangan"> Berat -->
                            <v-radio color="red" value="berat">
                              <template v-slot:label>
                                <strong class="red--text">Berat</strong>
                              </template>
                            </v-radio>
                          </label>
                        </v-col>
                        </v-radio-group>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="status_editor.inaktif_keterangan == 'Inaktif'">
                  <v-row>
                    <v-col cols="2">
                      <label>Keterangan Inaktif</label>
                    </v-col>
                    <v-col>
                      <v-textarea outlined dense v-model="status_editor.keterangan"></v-textarea>
                    </v-col>
                  </v-row>
                </div>
                <div>
                  <v-row>
                    <v-col cols="2">
                      <b><label>Dari Tanggal</label></b>
                    </v-col>
                    <v-col cols="3">
                      <!-- <v-text-field outlined dense v-model="status_editor.inaktif_mulai"></v-text-field> -->
                      <v-menu
                        v-model="fromDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        min-width="290px"
                        transition="scale-transition"
                        lazy offset-y full-width>
                        <template v-slot:activator="{ on }">
                          <v-text-field v-show="!showItem" label="Tanggal Mulai" append-icon="mdi-calendar" outlined dense readonly v-model="status_editor.inaktif_mulai" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker
                        locale="in-ID"
                        v-model="status_editor.inaktif_mulai"
                        no-title
                        @input="fromDateMenu = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="1"></v-col>
                    <v-col cols="2">
                      <b><label>Sampai Dengan</label></b>
                    </v-col>
                    <v-col cols="3">
                      <!-- <v-text-field outlined dense v-model="status_editor.inaktif_selesai"></v-text-field> -->
                      <v-menu
                        v-model="endDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        min-width="290px"
                        transition="scale-transition"
                        lazy offset-y full-width>
                        <template v-slot:activator="{ on }">
                          <v-text-field v-show="!showItem" append-icon="mdi-calendar" outlined dense readonly v-model="status_editor.inaktif_selesai" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker
                        locale="in-ID"
                        v-model="status_editor.inaktif_selesai"
                        no-title
                        @input="endDateMenu = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-expand-transition>
          </v-row>
          <v-row class="justify-end align-end" v-if="showMenuStatus">
            <v-btn small class="ma-1" color="red white--text" @click="showMenuStatus = false">Sembunyikan</v-btn>
          </v-row>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn class="primary" @click="save()">Simpan</v-btn>
          <v-btn @click="dialogEditStatus = false" color="yellow darken-1">tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- detail pegawai -->
    <v-dialog v-model="detailDialog" max-width="700px" v-if="detailData">
      <v-card >
        <v-card-title class="pa-0 mt-0">
          <v-toolbar flat>
            <v-toolbar-title>{{crudSettings.detailTitle}} <span class="font-weight-medium" ><q>{{detailData['Nama']}}</q> </span> </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="detailDialog=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <loading v-if="detailLoading"></loading>
          <div v-else>
            <table class="text-body-1 font-weight-normal">
              <tr v-for="(v,k,i) in detailData" :key="i">
                <td v-if="!k.match(/noDisplay.*/)" class="px-5 py-2">{{k}}</td>
                <td v-else></td>
                <td v-html="v"></td>
              </tr>
            </table>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- tambah peran -->
    <v-dialog v-model="dialogRole" max-width="800px">
      <v-toolbar>
        <v-toolbar-title>Ubah Peran</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialogRole=false"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card>
        <loading v-if="loading"></loading>
        <v-card-title>
          <v-avatar size="40" class="mr-2">
            <v-img :src="getImg(itemUser.nip,'jpeg')" @error="getImg(itemUser.nip,'jpg')" class="grey lighten-2">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
          {{itemUser.peg_nama}}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-row v-if="userRoles.length > 0">
                <v-list>
                  <v-list-item
                     v-for="(role, i) in userRoles" :key="i">
                     <v-list-item-content>{{role}}</v-list-item-content>
                     <v-list-item-action>
                       <v-btn icon @click="deleteUserRole(role)"> <v-icon>mdi-delete</v-icon></v-btn>
                     </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="5">
                  <v-select v-model="chosenRole" label="peran" :items="availableRoles"></v-select>
                </v-col>
                <v-col cols="2">
                  <v-btn class="secondary" @click="addUserRole" :disabled="userRoles.length >= 5"><v-icon>mdi-plus</v-icon> Tambah Peran</v-btn>
                </v-col>
              </v-row>
              <v-row v-if="chosenRole === 'PLH'">
                <v-col cols='12'>
                  <v-select label="Pilih Pelaksana tugas harian" v-model="chosenPLH" :items="availablePLH"></v-select>
                </v-col>
                <v-col
                   cols="12"
                   sm="6"
                 >
                   <v-date-picker
                     v-model="datesPLH"
                     range
                   ></v-date-picker>
                 </v-col>
                 <v-col
                   cols="12"
                   sm="6"
                 >
                   <v-text-field
                     v-model="dateRangeText"
                     label="Rentang Tanggal"
                     prepend-icon="mdi-calendar"
                     readonly
                   ></v-text-field>
                 </v-col>
              </v-row>
              <v-row v-if="chosenRole === 'PLT'">
                <v-col>
                  <v-select label="Pilih Pelaksana Tugas" v-model="chosenPLT" :items="availablePLT"></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer><v-btn class="primary">simpan</v-btn>
          <v-btn @click="dialogRole=false">batal</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- reset password -->
    <v-dialog v-model="dialogReset" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Reset Password</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              Berikut password baru, silahkan cata dan sampaikan kepada yang bersangkutan:
            </v-col>
            <v-col cols="3">
              <b><h3 class="display-2 my-2">{{reset_password}}</h3></b>
            </v-col>
            <v-col cols="2" class="mt-2">
              <v-btn color="primary" class="ma-1" @click="copyPassword()">Copy</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogReset=false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import _ from 'lodash'
import Crud from '@/components/common/Crud'
import DashboardService from '@/services/DashboardService'
import ProfilePegawaiService from '@/services/ProfilePegawaiService'
// import UserManagementService from '@/services/UserManagementService'
import Loading from '@/components/base/Loading'
import store from '@/store'

export default {
  components:{
    Crud,
    Loading
  },

  data(){
    return{
      headers:[],
      listSKPD:[],
      skpd:null,
      forceUpdate:false,
      userData:{},
      loading:false,

      dialogRole:false,
      userRoles:[],
      availableRoles:[],
      availablePLH:[],
      availablePLT:[],
      chosenRole:'',
      chosenPLT:'',
      chosenPLH:'',
      datesPLH:[],

      dialogReset:false,
      detailData:{},
      detailPegawai:{},
      itemUser:{},
      crudSettings:{
        title:'Manajemen User',
        detailTitle:'Detail',
        enableEdit:false,
        enableDetail:false,
        deleteTitleKey:'peg_nama'
      },
      postForm:{
        name:{
          type: 'text',
        },
        password:{
          type:'password'
        }
      },
      reset_password: null,
      avatarImages:{},
      itemsPerPage:10,
      loadingResetPassword:-1,
      detailDialog:false,
      detailLoading:false,
      itemsEditStatus:{},
      dialogEditStatus:false,
      loadingEditStatus:false,
      headersEditStatus:[
        {text:'Status', value:'status', sortable:false, class:'header-index-profile'},
        {text:'Dari Tanggal', value:'dari_tanggal', sortable:false, class:'header-index-profile'},
        {text:'Sampai Tanggal', value:'sampai_tanggal', sortable:false, class:'header-index-profile'},
        {text:'Aksi', value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      loadingTableEditStatus:false,
      status_editor:{},
      toggle:false,
      statusInaktif: [],
      showMenuStatus:false,
      showItem:false,
      fromDateMenu:false,
      endDateMenu: false,
    }
  },

  watch:{
    // skpd: function(val){
    //   this.forceUpdate = true
    // },
    dialogRole: function(val){
      val || this.closeRole()
    }
  },

  created(){
    ProfilePegawaiService.listSKPD().then(response=>{
      this.listSKPD = response.data
    })

    this.headers = [
      {text:'No', value:'no', sortable:false, class:'header-index-profile'},
      {text:'NIP', value:'nip', sortable:false, class:'header-index-profile'},
      // {text:'Nama', value:'peg_nama', sortable:false, class:'header-index-profile'},
      {text:'Nama', value:'nama', sortable:false, class:'header-index-profile'},
      {text:'Jabatan', value:'jabatan', sortable:false, class:'header-index-profile', width:'25%'},
      {text:'Pangkat', value:'pangkat', sortable:false, class:'header-index-profile'},
      // {text:'Job Value', value:'job_value', sortable:false, class:'header-index-profile'},
      {text:'Job Value', value:'job value', sortable:false, class:'header-index-profile'},
      {text:'Aksi', value:'aksi', sortable:false}
    ]
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)

    this.availableRoles=['a','b','c','d','e','f','PLH','PLT']
    for (let i = 0; i< this.availableRoles.length; i++){
      if (this.availableRoles[i] !== 'PLH' || this.availableRoles[i] !== 'PLT'){
        this.availablePLT.push('PLT-' + this.availableRoles[i])
      }
    }
    this.availablePLH=['PLH-a','PLH-b']
    this.showMenuStatus = false
  },

  computed:{
    dateRangeText () {
      return this.datesPLH.join(' ~ ')
    },
  },

  mounted(){
    this.$watch(vm => [vm.skpd, vm.itemsPerPage, vm.search], val =>{
      // reset halaman, kembali ke halaman satu
      this.page = 1
      // update data pegawai
      this.updateTable(val)
    },{immediate:true, deep:false})
  },

  methods:{
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl
      }
      return ''
    },

    getUserData(item){
      this.itemUser = _.clone(item)
      DashboardService.pegawai(item.nip).then(response=>{
        console.log(response.data)
        this.detailPegawai = response.data
      })
    },

    deleteUserRole(role){
      this.userRoles = this.userRoles.filter(x => x != role)
    },

    closeRole(){
      this.itemUser = {}
      this.userRoles = []
      this.chosenRole = ''
    },

    addUserRole(){
      if( this.userRoles.length < 5 && !this.userRoles.includes(this.chosenRole) && this.chosenRole !== 'PLH' && this.chosenRole !== 'PLT' ){
        this.userRoles.push(this.chosenRole)
      } else if (this.chosenRole === 'PLH' && !this.userRoles.includes(this.chosenPLH)) {
        this.userRoles.push(this.chosenPLH)
      } else if (this.chosenRole === 'PLT' && !this.userRoles.includes(this.chosenPLT)) {
        this.userRoles.push(this.chosenPLT)
      }
    },

    // getImg(nip,ext){
    //   let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
    //   this.avatarImages[nip] = avatarImgUrl
    //   return avatarImgUrl
    // },

    resetPassword(item){
      let pwd = Math.floor(100000 + Math.random() * 900000)
      this.loadingResetPassword = item.nip
      ProfilePegawaiService.resetPassword(item.nip, pwd.toString(), pwd.toString()).then(response => {
        let res = response.data
        this.reset_password = pwd.toString()
        if (res.status == '200'){
          this.dialogReset=true
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
        this.loadingResetPassword = -1
      })
      console.log('reset',item)
    },

    updateTable(val){
      // this.forceUpdate = false
      // console.log('updateTable val value=>', val)
      // if (val){
      //   UserManagementService
      //   .list({
      //       skpd:this.skpd,
      //       cari:val.search, row_per_page:val.itemsPerPage, page:val.page})
      //   .then((response)=>{
      //     this.userData = response.data
      //   })
      // }
      this.loading = true
      ProfilePegawaiService.listPegawai({skpd:this.skpd?this.skpd:val.skpd,cari:val.search,row_per_page:val.itemsPerPage,page:val.page}).then(response=>{
        this.loading = false
        this.userData = response.data
        for (let i = 0; i < this.userData.length; i++){
          let nip = this.userData[i].nip
          this.getImg(nip, 'jpeg')
        }
      })
    },

    getDetailUser(val){
      if(val){
        // UserManagementService.get(val).then(response=>{
        //   this.detailData = response.data.data[0]
        //   let data = response.data.data[0]
          this.detailData = {
            "NIP":val.nip,
            // "Nama":val.peg_nama,
            "Nama":val.nama,
            "Jabatan":val.jabatan,
            "Golongan":val.pangkat,
            "Job Value":val['job value'],
            "noDisplay":"<i>Apabila terdapat ketidaksesuaian data, silakan hubungi Admin SIMPEG untuk memperbarui data</i>"
          }
        // })}
      }
      this.detailLoading = true
      this.detailDialog = true
      setTimeout(() => {
        this.detailLoading = false
      }, 500);
    },

    copyPassword(){
      // Copy the text inside the text field
      navigator.clipboard.writeText(this.reset_password);
      // Alert the copied text
      alert("Password berhasil di-copy: " + this.reset_password);
    },

    editStatus(val){
      this.itemsEditStatus = val
      this.loadingEditStatus = val.nip
      this.loadingTableEditStatus = true
      this.showMenuStatus = false
      ProfilePegawaiService.getStatusPegawai(val).then(response => {
        let res = response.data
        this.loadingTableEditStatus = false
        this.itemsEditStatus = res
        if (res){
          store.commit('snackbar/setSnack',{message: res.message ? res.message : 'Get Data Status Pegawai Berhasil!', color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message ? res.message : 'Get Data Status Pegawai Gagal!', color:'error'})
        }
        this.loadingEditStatus = -1
      })
      ProfilePegawaiService.getListInaktif(val).then(response => {
        let res = response.data.data
        this.statusInaktif = res
      }).finally(()=>{
        this.dialogEditStatus = true
      })
    },

    showListStatus(val){
      this.showMenuStatus = true
      if (val == null) {
        this.status_editor = { id: null };
      } else {
        this.status_editor = val;
      }
    },

    save(){
      this.status_editor.peg_id = this.itemsEditStatus.peg_id
      this.status_editor.inaktif_ket = this.status_editor.keterangan
      if (!this.status_editor.inaktif_mulai || !this.status_editor.inaktif_selesai) {
        store.commit('snackbar/setSnack',{message: 'Tanggal tidak valid', color:'error'})
        return;
      }
      if (this.status_editor.inaktif_mulai > this.status_editor.inaktif_selesai) {
        store.commit('snackbar/setSnack',{message: 'Tanggal mulai tidak sesuai dengan tanggal selesai', color:'error'})
        return;
      }
      ProfilePegawaiService.simpanStatusPegawai(this.status_editor).then(response => {
        let res = response.data
        if (res){
          store.commit('snackbar/setSnack',{message: res.message ? res.message : 'Status Pegawai Berhasil Diupdate!', color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message ? res.message : 'Status Pegawai Gagal Diupdate!', color:'error'})
        }
        let val = {itemsPerPage:10, page: 1, skpd: this.skpd, cari: this.search}
        this.updateTable(val)
      }).finally(()=>{
        this.dialogEditStatus = false
      })
    },

    hapusStatus(val){
      if (!confirm("Apakah anda yakin?")) return;
      ProfilePegawaiService.hapusStatusPegawai(val.id).then(response => {
        let res = response.data
        if (res){
          store.commit('snackbar/setSnack',{message: res.message ? res.message : 'Status Pegawai Berhasil Dihapus!', color:'success'})
        } else {
          store.commit('snackbar/setSnack',{message: res.message ? res.message : 'Status Pegawai Gagal Dihapus!', color:'error'})
        }
        let val = {itemsPerPage:10, page: 1, skpd: this.skpd, cari: this.search}
        this.updateTable(val)
      }).finally(()=>{
        this.dialogEditStatus = false
      })
    }
  },
}
</script>

<style lang="css" scoped>
</style>
